import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isExpired } from "react-jwt";
import LocalStorageService from "./services/LocalStorageService";

function AuthenticatedRoute({ component: Component, ...rest }) {

    const authToken = LocalStorageService.getAccessToken()

    const isTokenValid = authToken !== null && !isExpired(authToken)

    return(
        <Route {...rest} render={
            (props) => isTokenValid ?
                (<Component {...rest} />):(<Redirect to="/login"  />)
        }
        />
    );
}

export default AuthenticatedRoute;