import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import {UserContext} from "./context/user";
import Routes from "./Routes";
import NavBar from "./components/navbar.js";
import LocalStorageService from "./services/LocalStorageService";
import './App.scss'


class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      user : {
        contactId: LocalStorageService.getId(),
        email: LocalStorageService.getEmail()
      }
    }

    this.setContactId = this.setContactId.bind(this)
    this.setUserEmail = this.setUserEmail.bind(this)
  }

  setContactId = (id) => {
    LocalStorageService.saveId(id)
    this.setState({user: {...this.state.user, contactId: id}})
  }

  setUserEmail = (email) => {
    LocalStorageService.saveEmail(email)
    this.setState({ user: {...this.state.user, email: email}})
  }

  render() {
    return (
          <UserContext.Provider value={{ user: this.state.user, setContactId: this.setContactId, setUserEmail: this.setUserEmail }}>
          <Router basename="/">
            <NavBar />
            <Routes />
          </Router>
          </UserContext.Provider>)
  }
}

export default App;
