import React from "react";
import VAUploadForm from "../components/va-upload/VAUploadForm";

function VAUpload(props) {
    return (
        <div className="container  mt-5  p-3 text-left bg-light">
           <h3 className="h3 text-center mt-3">VA hochladen</h3>
           <VAUploadForm />
        </div>
    );
}

export default VAUpload;