export const accountingTypes = [
  {
    id: 573906,
    name: "GWG",
    description: "Geringwertige Wirtschaftsgüter unter 800€ netto"
  },
  {
    id: 573912,
    name: "Verpflegung",
    description: "z.B. Essensbestellungen"
  },
  {
    id: 573914,
    name: "HOKO Team",
    description: "z.B. Skifahren"
  },
  {
    id: 573931,
    name: "Treibstoff",
    description: "z.B. Benzin, Diesel oder SUPER PLUS!"
  },
  {
    id: 573934,
    name: "Werbeartikel",
    description: "z.B. Stifte, Blöcke"
  },
  {
    id: 573935,
    name: "Printmedien",
    description: "z.B. Flyer, Katalog"
  },
  {
    id: 573936,
    name: "Werbung",
    description: "z.B. Litfaßsäulen, U-Bahn, Facebook"
  },
  {
    id: 573937,
    name: "Bewirtungskosten (Geschäftlich)",
    description: "NUR WENN KUNDEN/LIEFERANTEN MITESSEN"
  },
  {
    id: 573940,
    name: "Teamwear",
    description: "z.B. Anzug, Tracht, Teamoutfit"
  },
  {
    id: 573942,
    name: "Teamevent",
    description: "z.B. Team-Building, Partnermessen"
  },
  {
    id: 573944,
    name: "Messeorganisation",
    description: "z.B. Getränkerechnungen, Einkauf Teamtage"
  },
  {
    id: 573947,
    name: "Technik",
    description: "z.B. Gaffer-Tape, Holz, Molton"
  },
  {
    id: 573949,
    name: "Bürobedarf",
    description: "NUR Artikel, die NICHT zur Werbung dienen"
  },
  {
    id: 573950,
    name: "Fachliteratur",
    description: "z.B. Bücher, Zeitschriften"
  },
];

export default function getCategoryName(id) {
  const type = accountingTypes.filter(accType => accType.id === parseInt(id))
  return type.length > 0 ? type[0].name : ''
}

export function getCategoryDescription(id) {
  const type = accountingTypes.filter(accType => accType.id === parseInt(id))
  return type.length > 0 ? type[0].description : ''
}