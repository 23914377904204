const USER_SESSION_ATTRIBUTE_TOKEN = 'token';
const USER_SESSION_ATTRIBUTE_REFRESH_TOKEN = 'refresh';
const USER_SESSION_ATTRIBUTE_EMAIL = 'email';
const USER_SESSION_ATTRIBUTE_CONTACT_ID = 'id';
const USER_SESSION_ATTRIBUTE_VERIFIER = 'verifier';

class LocalStorageService {

  saveSession(access_token, refresh_token) {
    localStorage.setItem(USER_SESSION_ATTRIBUTE_TOKEN, access_token)
    localStorage.setItem(USER_SESSION_ATTRIBUTE_REFRESH_TOKEN, refresh_token)
  }

  removeSession() {
    localStorage.removeItem(USER_SESSION_ATTRIBUTE_TOKEN)
    localStorage.removeItem(USER_SESSION_ATTRIBUTE_REFRESH_TOKEN)
    localStorage.removeItem(USER_SESSION_ATTRIBUTE_VERIFIER)
    localStorage.removeItem(USER_SESSION_ATTRIBUTE_CONTACT_ID)
    localStorage.removeItem(USER_SESSION_ATTRIBUTE_EMAIL)
  }

  getAccessToken() {
    return localStorage.getItem(USER_SESSION_ATTRIBUTE_TOKEN);
  }

  getRefreshToken() {
    return localStorage.getItem(USER_SESSION_ATTRIBUTE_REFRESH_TOKEN);
  }

  saveEmail(email) {
    localStorage.setItem(USER_SESSION_ATTRIBUTE_EMAIL, email)
  }

  getEmail() {
    return localStorage.getItem(USER_SESSION_ATTRIBUTE_EMAIL);
  }

  saveId(id) {
    localStorage.setItem(USER_SESSION_ATTRIBUTE_CONTACT_ID, id)
  }

  getId() {
    return localStorage.getItem(USER_SESSION_ATTRIBUTE_CONTACT_ID);
  }

  getVerifier() {
    return localStorage.getItem(USER_SESSION_ATTRIBUTE_VERIFIER);
  }

  saveVerifier(verifier) {
    localStorage.setItem(USER_SESSION_ATTRIBUTE_VERIFIER, verifier)
  }
}

export default new LocalStorageService()