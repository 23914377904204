import React from 'react';
import axios from "axios";
import {Redirect} from 'react-router-dom'
import {CommonLoading} from "react-loadingg";
import LocalStorageService from "./LocalStorageService";
import * as crypto from 'crypto-browserify'
import btoa from 'btoa'


class LoginHandler extends React.Component {

    constructor(props) {
        super(props);
        this.authDomain = process.env.REACT_APP_AUTH_DOMAIN
        this.clientId = process.env.REACT_APP_AUTH_CLIENT_ID
        this.baseUrl = process.env.REACT_APP_BASE_URL

        this.state = {
            isLoggedIn: false
        }
    }

    base64urlEncode(value) {
        let base64 = btoa(value);
        base64 = base64.replace(/\+/g, '-');
        base64 = base64.replace(/\//g, '_');
        base64 = base64.replace(/=/g, '');
        return base64;
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(this.props.location.search)
        const code = searchParams.get('code')
        if (code == null){
            const challenge = this.getChallenge()
            window.location.href = `${this.authDomain}/oauth2/authorize?identity_provider=AzureIdentityProvider&redirect_uri=${this.baseUrl}/login&response_type=CODE&client_id=${this.clientId}&code_challenge=${challenge}&code_challenge_method=S256&scope=email openid`;
        } else {
            this.getTokenWithCode(code);
        }
    }

    sha256(buffer){
      return crypto.createHash('sha256').update(buffer).digest();
    }

    getChallenge() {
        const verifier = this.base64urlEncode(crypto.randomBytes(48))

        LocalStorageService.saveVerifier(verifier);

        return this.base64urlEncode(this.sha256(verifier));
    }

    async getTokenWithCode(code) {
        try {
            const verifier = LocalStorageService.getVerifier()
            const data = `grant_type=authorization_code&client_id=${this.clientId}&code_verifier=${verifier}&code=${code}&redirect_uri=${this.baseUrl}/login`;

            const response = await axios.post(`${this.authDomain}/oauth2/token`, data)

            const { id_token, refresh_token } = response.data
            LocalStorageService.saveSession(id_token, refresh_token)
            this.setState({isLoggedIn: true});
        } catch(error) {
            console.error("Failed to authenticate user", error)
        }
    }

    render() {
        if (this.state.isLoggedIn){
            return <Redirect to="/" />;
        } else{
            return (
                <CommonLoading color={'#006394'}/>
            )
        }
    }
}

export default LoginHandler;