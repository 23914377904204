import React from 'react';
import {Slide, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ContactForm.scss';
import {CommonLoading} from 'react-loadingg';
import {http_client} from "../../services/HttpClient";
import {UserContext} from "../../context/user";

toast.configure({
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: true,
  transition: Slide
})

class ContactForm extends React.Component {
  static contextType = UserContext
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      email: '',
      id: '',
      input: {
        givenname: '',
        surname: '',
        iban: '',
        bic: '',
      },
      errors: {
        error: '',
      }
    };

    this.form = React.createRef();

    this.baseUrl = process.env.REACT_APP_BACKEND_URL;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState(
        {input: {...this.state.input, [event.target.name]: event.target.value}
        });
  }

  handleValidation() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (!input["givenname"]) {
      isValid = false;
      errors["givenname"] = "Bitte trage deinen Vornamen ein.";
    }

    if (!input["surname"]) {
      isValid = false;
      errors["surname"] = "Bitte trage deinen Nachnamen ein.";
    }

    if (!input["iban"]) {
      isValid = false;
      errors["iban"] = "Bitte trage deinen IBAN ein.";
    }

    if (typeof input["iban"] !== "undefined") {
      const pattern = new RegExp(/^[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?:[ ]?[0-9]{1,2})?/);
      if (!pattern.test(input["iban"])) {
        isValid = false;
        errors["iban"] = "Bitte trage eine gültige IBAN ein.";
      }
    }

    if (!input["bic"]) {
      isValid = false;
      errors["bic"] = "Bitte trage eine gültige BIC ein.";
    }

    this.setState({
      errors: errors
    });

    return isValid;
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (this.handleValidation()) {
      this.setState({processing: true});
      const data = this.state.input;

      if (this.state.id) {
        data['id'] = this.state.id
      }

      try {
        const response = await http_client.put('/contact', data)
        const { contactId } = response.data
        this.setState({id: contactId})
        toast.success('Kontaktdaten erfolgreich gespeichert')
      } catch (error) {
        console.error(error)
        toast.error('Kontaktdaten konnten nicht gespeichert werden.');
      }
      finally {
        this.setState({processing: false});
      }
    }
  }

  async getContact(id) {
    this.setState({processing: true})

    try {
      const response = await http_client.get('/contact?id=' + id)
      const data = response.data
      const input = this.state.input
      input.givenname = data.givenname
      input.surname = data.surname
      input.iban = data.iban
      input.bic = data.bic

      this.setState({input})

    } catch (error) {
      console.error("Failed to fetch contact data for id " + id)
    }
    finally {
      this.setState({processing: false})
    }
  }

  componentDidMount() {
    const {user} = this.context

    if (user.email){
      this.setState({email: user.email})
    }

    if (user.contactId){
      return this.getContact(user.contactId)
    }
  }

  render() {
    const loading = this.state.processing
    return (
        <div className="container text-left bg-light p-md-5">
          {loading
              ? <CommonLoading color={'#006394'}/>
              : null
          }
          <form ref={this.form} className="form-group" onSubmit={this.handleSubmit}>
            <fieldset disabled={loading ? "disabled" : null}>
              <label className="d-block pb-2">
                Email:
                <input type="text" className="form-control" disabled name="email" value={this.state.email}
                       onChange={this.handleChange}/>
              </label>
              <label className="d-block pb-2">
                Vorname:
                <input type="text" className="form-control" id="givenname" name="givenname"
                       value={this.state.input.givenname} onChange={this.handleChange}/>
                <span style={{color: "red"}}>{this.state.errors["givenname"]}</span>
              </label>
              <label className="d-block pb-2">
                Nachname:
                <input type="text" className="form-control" id="surname" name="surname" value={this.state.input.surname}
                       onChange={this.handleChange}/>
                <span style={{color: "red"}}>{this.state.errors["surname"]}</span>
              </label>
              <label className="d-block pb-2">
                IBAN:
                <input type="text" className="form-control" id="iban" name="iban" value={this.state.input.iban}
                       onChange={this.handleChange}/>
                <span style={{color: "red"}}>{this.state.errors["iban"]}</span>
              </label>
              <label className="d-block pb-2">
                BIC:
                <input type="text" className="form-control" id="bic" name="bic" value={this.state.input.bic}
                       onChange={this.handleChange}/>
                <span style={{color: "red"}}>{this.state.errors["bic"]}</span>
              </label>
              <input type="submit" className="btn btn-primary btn-lg float-right" id="submit" value="Speichern"/>
            </fieldset>
          </form>
        </div>
    );
  }
}

export default ContactForm;
