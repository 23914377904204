import axios from "axios";
import LocalStorageService from "./LocalStorageService";

const AUTH_URL = process.env.REACT_APP_AUTH_DOMAIN
const clientId = process.env.REACT_APP_AUTH_CLIENT_ID
export const API_URL = process.env.REACT_APP_BACKEND_URL
export const http_client = axios.create({baseURL: API_URL});

http_client.interceptors.request.use(req => {
      const token = LocalStorageService.getAccessToken()

      if (token) {
        req.headers['Authorization'] = 'Bearer ' + token
      }

      return req;

    },
    error => Promise.reject(error)
)

http_client.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {

        const refreshToken = LocalStorageService.getRefreshToken();

        const data = `grant_type=refresh_token&client_id=${clientId}&refresh_token=${refreshToken}`

        originalRequest._retry = true;
        return axios.post(`${AUTH_URL}/oauth2/token`, data)
            .then(res => {
              if (res.status === 200) {
                // 1) put token to LocalStorage
                const {id_token} = res.data
                LocalStorageService.saveSession(id_token, refreshToken)

                // 2) Change Authorization header
                originalRequest.headers['Authorization'] = 'Bearer ' + id_token;

                // 3) return originalRequest object with Axios.
                return axios(originalRequest);
              }
            })
      } else {
        return Promise.reject(error)
      }
})