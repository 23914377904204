import React from 'react';
import hoko_logo from '../static/images/HOKO2020.png'
import {Navbar, Nav} from 'react-bootstrap';
import {Link} from "react-router-dom";

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active:'1'
        };
    }

  render() {
    return (
        <Navbar expand="lg" bg="light" >
          <Navbar.Brand href="/">
            <img src={hoko_logo}  height="100" alt="HOKO Logo"/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto"
                onSelect={(selectedKey) =>  this.setState({active: selectedKey})}
                activeKey={this.state.active}>
              <Nav.Item className="pr-5 pb-2 d-flex justify-content-center"><Link className="h5 font-weight-bold text-secondary" to="/">Übersicht</Link></Nav.Item>
              <Nav.Item className="pr-5 pb-2 d-flex justify-content-center"><Link className="h5 font-weight-bold text-secondary" to="/va-upload">VA hochladen</Link></Nav.Item>
              <Nav.Item className="pr-5 pb-2 d-flex justify-content-center"><Link className="h5 font-weight-bold text-secondary" to="/contact-info">Kontaktdaten</Link></Nav.Item>
            </Nav>
            <Nav>
              <Nav.Item className="pr-5 pb-2 d-flex justify-content-center"><Link className="h5 font-weight-bold text-secondary" to="/logout">Logout</Link></Nav.Item>
            </Nav>

          </Navbar.Collapse>
        </Navbar>
     );
   }
}

export default NavBar;