import React from 'react';
import {Slide, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './VAUploadForm.js';
import { CommonLoading } from 'react-loadingg';
import {UserContext} from "../../context/user";
import {http_client} from "../../services/HttpClient";
import Alert from "react-bootstrap/Alert";
import {accountingTypes, getCategoryDescription} from "../../static/AccountingTypes";
import ImageCropper from "../ImageCropper/ImageCropper";
import 'react-image-crop/lib/ReactCrop.scss';


toast.configure({
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: true,
  transition: Slide
})

class VAUploadForm extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);

    this.state = {
      processing: false,
      imgSrc: '',
      contactId:'',
      input: {
        file: '',
        amount: '',
        category: 'null',
        description: ''
      },
      errors: {
        error:'',
        file:'',
        amount:'',
        category:'',
        description:''
      },
      categoryInfo: ''
    }

    this.initialInput = {...this.state.input}

    this.form = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleCrop = this.handleCrop.bind(this);
  }

  handleChange(event) {
    if (event.target.type === 'file') {
      if(event.target.files[0].type === 'application/pdf'){
        this.setState({input: {...this.state.input, [event.target.name]: event.target.files[0]}})
      } else {
        // show cropping only if file is an image
        this.showCroppingModal(event);
      }
    } else {
      this.setState({input: {...this.state.input, [event.target.name]: event.target.value}})
    }
  }

  handleSelectChange(event) {
    this.handleChange(event)
    const desc = getCategoryDescription(event.target.value)
    this.setState({categoryInfo: desc})
  }

  handleValidation() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (!input["file"]) {
      isValid = false;
      errors["file"] = "Bitte wähle ein Bild einer Rechnung aus.";
    }

    if (!input["amount"]) {
      isValid = false;
      errors["amount"] = "Bitte trage den Bruttobetrag ein.";
    }

    if (!input["category"] || input["category"] === 'null' ) {
      isValid = false;
      errors["category"] = "Bitte wähle eine Kategorie aus.";
    }

    if (!input["description"]) {
      isValid = false;
      errors["description"] = "Bitte trage eine Beschreibung ein.";
    }

    this.setState({
      errors: errors
    });

    return isValid;
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (this.handleValidation()) {
      this.setState({processing: true});
      const formData = new FormData(this.form.current);

      formData.set('file', this.state.input.file)

      formData.append('contactId', this.state.contactId)

      this.postFormData(formData)
          .then(res => {
            this.setState({input: {...this.initalInput}})
            toast.success('Upload erfolgreich');
          })
          .catch(err => toast.error('Upload fehlgeschlagen'))
          .finally(() => this.setState({processing: false}))
    }
  }

  async postFormData(formData) {
    try {
      await http_client.post('/invoice', formData)
    } catch (error) {
      console.error(error)
      toast.error('Upload fehlgeschlagen');
    }
  }

  showCroppingModal(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
          this.setState({ imgSrc: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  handleCrop(blob) {
    this.setState({input: {...this.state.input, file: blob}})
  }

  componentDidMount() {
    const { user } = this.context
    this.setState({contactId: user.contactId})
  }

  render() {

    const categoryOptions = accountingTypes.map(accType =>
        <option key={accType.id} value={accType.id}>{ accType.name }</option>
    )

    const categoryInfoAlert = this.state.categoryInfo ?
        <Alert className={'mt-2'} variant={'secondary'}>{ this.state.categoryInfo }</Alert> : null

    return (
      <div className="container p-md-5 text-left bg-light">
        { this.state.processing && <CommonLoading color={'#006394'} /> }
        { this.state.imgSrc && <ImageCropper onComplete={this.handleCrop} img={this.state.imgSrc} /> }
        <form ref={this.form} className="form-group" onSubmit={this.handleSubmit}>
          <fieldset disabled={this.state.processing ? "disabled" : null}>
          <label className="d-block pb-2">
            Rechnung auswählen:
            <input id="file" type="file" className="form-control-file" name="file" onChange={this.handleChange} accept={["image/jpeg", "image/png", "application/pdf"]} />
            <span style={{color: "red"}}>{this.state.errors.file}</span>
          </label>
          <label className="d-block pb-2">
            Betrag (brutto in €):
            <input id="amount" type="number" step="0.01" min="0.01" className="form-control" placeholder="z.B. 12.99€" name="amount" value={this.state.input.amount || ''} onChange={this.handleChange}/>
            <span style={{color: "red"}}>{this.state.errors.amount}</span>
          </label>

          <label className="d-block pb-2">
            Beschreibung:
            <input id="description" type="text" placeholder="z.B. Organisation Teamtag" className="form-control" name="description" value={this.state.input.description || ''} onChange={this.handleChange}/>
            <span style={{color: "red"}}>{this.state.errors.description}</span>
          </label>

            <label className="d-block pb-2">
            Kategorie:
            <select id="category" className="form-control" name="category" value={this.state.input.category || 'null'} onChange={this.handleSelectChange}>
              <option value="null">Wähle eine Kategorie aus:</option>
              { categoryOptions }
            </select>
            { categoryInfoAlert }
            <span style={{color: "red"}}>{this.state.errors.category}</span>
          </label>

          <input type="submit" className="btn btn-primary btn-lg float-right" id="submit" value="Upload"/>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default VAUploadForm;
