import React from "react";
import {http_client} from "../services/HttpClient";
import { Redirect } from 'react-router-dom';
import {UserContext} from "../context/user";
import VAOverview from "../components/va-overview/VAOverview";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

class Home extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      redirectToContact: false,
      contactId: '',
      email: ''
    }
  }

  async fetchContactId() {
    const {setUserEmail, setContactId} = this.context

    try {
      const response = await http_client.get('/contact-info')
      const {contactId, email} = response.data

      if(contactId && email){
        this.setState({contactId: contactId, email: email})

        setContactId(contactId)
        setUserEmail(email)
      }
    } catch (error) {
      if (error.response.status === 404){
        const {email} = error.response.data
        this.setState({email: email})
        setUserEmail(email)
        this.setState({processing: false, redirectToContact: true })
      } else {
        console.error(error)
      }
    }
  }

  componentDidMount() {
    const {user} = this.context
    if (user.contactId && user.email){
      this.setState({contactId: user.contactId, email: user.email})
    } else {
      this.setState({processing: true})
      this.fetchContactId()
    }
  }

  render() {
    if (this.state.redirectToContact) return (<Redirect to="/contact-info" />)

    return (
        <Container className="bg-light mt-5  p-5">
          <Row>
            <Col>
              <h3 className="text-center">Übersicht</h3>
              <VAOverview contactId={this.state.contactId}/>
            </Col>
          </Row>
        </Container>
    )
  }
}

export default Home;