import React from 'react'
import {http_client} from "../../services/HttpClient";
import { CommonLoading } from 'react-loadingg';
import "./InvoiceViewer.scss"

export default class InvoiceViewer extends React.Component{

  constructor(props){
    super(props)

    this.state = {
      content: '',
      mediaType: '',
      processing: true
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.activeVoucher === this.props.voucherId && !prevState.content){
      this.loadImage(this.props.activeVoucher)
          .then(res => {
            const data = Buffer.from(res.data, 'binary').toString('base64')
            this.setState({mediaType: res.headers['content-type'], content: data})
          })
          .finally(() => this.setState({processing: false}))
    }
  }

  async loadImage(voucherId) {
    try {
      return await http_client.get(`/bill?id=${voucherId}`, {
        responseType: 'arraybuffer'
      })
    } catch (e){
      console.error(e)
    }
  }

  render() {
    const loading = this.state.processing ? <CommonLoading color={'#006394'}/> : null

    return (
        <div className="mt-3 invoice-img">
          {loading}
          <img alt="" src={`data:${this.state.mediaType};base64,${this.state.content}`} width="100%"/>
        </div>
    )
  }
}