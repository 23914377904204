import React from "react";
import ReactCrop from 'react-image-crop';
import {Button, Modal} from "react-bootstrap";
import './ImageCropper.scss'

export default class ImageCropper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
      crop: {
        unit: '%',
        x: 5,
        y: 5,
        width: 30,
        height: 50,
        maxHeight: 100,
        maxWidth: 100,
        keepSelection: true
      },
      croppedImageBlob: ''
    };

    this.saveAndCloseModal = this.saveAndCloseModal.bind(this);
  }

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({crop});
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageBlob = await this.getCroppedImg(
          this.imageRef,
          crop,
          'newFile.jpeg'
      );
      this.setState({croppedImageBlob});
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        resolve(blob);
      }, 'image/jpeg');
    });
  }

  saveAndCloseModal() {
    this.props.onComplete(this.state.croppedImageBlob)
    this.setState({showModal: false})
  }

  render() {
    return (
        <Modal show={this.state.showModal} centered size={'xl'} dialogClassName="cropping-modal">
          <Modal.Header>
            <Modal.Title>
              Rechnung zuschneiden
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactCrop
                src={this.props.img}
                crop={this.state.crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.saveAndCloseModal}>
              Save Image
            </Button>
          </Modal.Footer>
        </Modal>
    )
  }
}