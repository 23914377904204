import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthenticatedRoute from "./AuthenticatedRoute";
import LoginHandler from "./services/LoginHandler";
import LogoutHandler from "./services/LogoutHandler";
import Home from "./pages/Home";
import VAUpload from "./pages/VAUpload";
import Contact from "./pages/Contact";

function Routes() {

  return (
      <Switch>
        <Route path="/login" component={LoginHandler} />
        <Route path="/logout" component={LogoutHandler} />
        <AuthenticatedRoute exact path="/" component={Home} />
        <AuthenticatedRoute path="/va-upload" component={VAUpload} />
        <AuthenticatedRoute path="/contact-info" component={Contact} />
      </Switch>
  );
}

export default Routes;