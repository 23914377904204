import React from 'react'
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import {http_client} from "../../services/HttpClient";
import Badge from "react-bootstrap/Badge";
import InvoiceViewer from "../invoice-viewer/InvoiceViewer";
import {CommonLoading} from 'react-loadingg';
import Jumbotron from "react-bootstrap/Jumbotron";
import Table from "react-bootstrap/Table";
import NumberFormat from "react-number-format"
import './VAOverview.scss'
import Button from "react-bootstrap/Button";
import getCategoryName from "../../static/AccountingTypes";

export default class VAOverview extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      vouchers: [],
      activeVoucher: '',
      processing: true
    }

    this.print = this.print.bind(this)
    this.deleteInvoice = this.deleteInvoice.bind(this)
  }

  print(e) {
    e.preventDefault()
    window.print()
  }

  deleteInvoice(voucherId) {
    this.setState({processing: true})
    const voucherList = this.state.vouchers.filter(voucher => voucher.id !== voucherId)

    http_client.delete(`/invoice/${voucherId}`)
        .then(res => {
          this.setState({vouchers : voucherList})
        })
        .finally(() => this.setState({processing: false}))
  }

  async fetchInvoices(contactId) {
    try {
      const res = await http_client.get(`/invoice?id=${contactId}`)
      const voucherList = res.data
      this.setState({vouchers: voucherList, processing: false})
    } catch (e) {
      this.setState({processing: false})
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.contactId && prevProps.contactId !== this.props.contactId){
      this.fetchInvoices(this.props.contactId)
    }
  }

  getStatusBadge(status) {
    if (status === '50'){
      return (<Badge className="float-right p-2" variant="danger">DRAFT</Badge>)
    } else if (status === '100'){
      return (<Badge className="float-right p-2" variant="warning">IN BEARBEITUNG</Badge>)
    } else if (status === '1000') {
      return (<Badge className="float-right p-2" variant="success">FERTIG</Badge>)
    } else {
      return null
    }
  }

  render() {
    const emptyVoucher = this.state.vouchers.length === 0 && !this.state.processing ?
        <Alert className="text-center mt-5" variant="dark">
          Du hast noch keine VAs eingereicht.
        </Alert>
        : null

    const cards = this.state.vouchers.map(voucher =>
        <Card key={voucher.id}>
          <Accordion.Toggle as={Card.Header} eventKey={voucher.id}>
            <Badge className="p-2 mr-4" variant="primary">{voucher.voucherNr}</Badge>

            <span className="font-weight-bold">{voucher.description}</span>

            {this.getStatusBadge(voucher.status)}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={voucher.id}>
            <Card.Body>
              <Jumbotron>
                <h2>Veranstaltungsabrechnung</h2>

                <Table className="mt-4" size="sm" borderless="true">
                  <tbody>
                  <tr>
                    <td>Beschreibung</td>
                    <td className="font-weight-bold">{voucher.description}</td>
                  </tr>
                  <tr>
                    <td>VA-Nummer</td>
                    <td className="font-weight-bold">{voucher.voucherNr}</td>
                  </tr>
                  <tr>
                    <td>Betrag</td>
                    <td className="font-weight-bold">
                      <NumberFormat
                        displayType="text"
                        isNumericString={true}
                        value={voucher.amount}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        suffix={'€'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Kategorie</td>
                    <td className="font-weight-bold">{ getCategoryName(voucher.accTypeId) }</td>
                  </tr>
                  </tbody>
                </Table>

                <InvoiceViewer activeVoucher={this.state.activeVoucher} voucherId={voucher.id} />

                {
                  voucher.status === '50' ?
                    <Button className="d-print-none float-left mt-3" variant={'secondary'} onClick={() => this.deleteInvoice(voucher.id)}>Löschen</Button>
                    :
                    null
                }
                <Button className="d-print-none float-right mt-3" variant={'primary'} onClick={this.print}>Drucken</Button>
              </Jumbotron>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
    )

    return (
        <div>
          { this.state.processing
              ? <CommonLoading color={'#006394'} />
              : null
          }

          {emptyVoucher}

          <Accordion onSelect={key => this.setState({activeVoucher: key})} activeKey={this.state.activeVoucher}>
            {
              cards
            }
          </Accordion>
        </div>
    )
  }
}